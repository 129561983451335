exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-components-layout-tsx": () => import("./../../../src/pages/components/layout.tsx" /* webpackChunkName: "component---src-pages-components-layout-tsx" */),
  "component---src-pages-components-project-tsx": () => import("./../../../src/pages/components/project.tsx" /* webpackChunkName: "component---src-pages-components-project-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-servicesncontact-tsx": () => import("./../../../src/pages/servicesncontact.tsx" /* webpackChunkName: "component---src-pages-servicesncontact-tsx" */),
  "component---src-pages-tuitionfund-tsx": () => import("./../../../src/pages/tuitionfund.tsx" /* webpackChunkName: "component---src-pages-tuitionfund-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

